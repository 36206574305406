<template>
  <v-row no-gutters justify="center" class="pibot-users-list">
    <v-col cols="12" md="8" lg="6">
      <v-alert v-if="error" text dense type="error">
        {{ error }}
      </v-alert>

      <template v-if="!error">
        <v-card flat v-if="$grantAccess($me, 'createAny', 'profile')">
          <v-card-subtitle v-text="`New User`" class="text-overline px-0" />

          <v-card-text class="pa-0">
            <v-btn
              color="success"
              depressed
              small
              :to="'users/new'"
            >
              Create new user
            </v-btn>

            <v-divider class="mt-5 mb-2" />
          </v-card-text>
        </v-card>

        <v-card flat>
          <v-card-subtitle v-text="`Users`" class="text-overline px-0" />

          <v-card-text class="pa-0">
            <v-row>
              <v-col cols="12" md="6" class="pb-0" v-for="user in usersList" :key="user._id">
                <v-card :outlined="user._id !== $me._id" :elevation="user._id === $me._id ? 4 : null" :class="{inactive: user.status === 0}">
                  <v-card-title v-text="`User: ${user.username}`" />

                  <v-card-subtitle class="text-capitalize">
                    Role: {{ user.role === user._id ? 'custom' : user.role }}
                  </v-card-subtitle>

                  <v-card-actions>
                    <v-spacer />

                    <v-btn v-if="user.status" text small color="primary darken-2" :to="user._id !== $me._id ? `user/${user._id}` : '/settings/profile'">
                      Edit
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'pibot-settings-users-list',
  data: () => ({
    error: false
  }),
  computed: {
    ...mapState({
      users: state => state.settings.users
    }),
    usersList () {
      if (!this.users) return []

      // Filter users based on permissions
      const list = this.users.filter(u => {
        const { role } = u
        if (role === 'admin') return this.$ac.can(this.$me.role).readAny('admin').granted
        if (role === 'superadmin') return this.$ac.can(this.$me.role).readAny('superadmin').granted
        return this.$ac.can(this.$me.role).readAny('profile').granted
      })

      // Place the current user at the start of the list
      const sorted = list.sort((a, b) => {
        if (a._id === this.$me._id) return -1
        if (b._id === this.$me._id) return 1
        return 0
      })

      return sorted
    }
  },
  methods: {
    ...mapActions({
      fecthUsers: 'settings/socket_fetchUsers'
    })
  },
  beforeRouteEnter (to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    next(vm => {
      vm.$grantAccess(vm.$me, 'readAny', 'profile', true)
    })
  },
  created () {
    if (this.users) return
    this.fecthUsers()
      .then(() => {
        this.error = false
      })
      .catch((error) => {
        this.error = error.message
      })
  }
}
</script>

<style>
.pibot-users-list .inactive {
  border-color: var(--v-error-base) !important;
}
</style>
